import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { zIndex } from '@/app/styles/base/zIndex';

export const _bannerContainer = styled.div`
    width: 100%;
    position: relative;
    will-change: transform;

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-pagination {
        top: auto !important;
        bottom: 24px !important;
        z-index: 10;
        transform: translateZ(0);

        ${mediaDown('mobile')} {
            bottom: 16px !important;
        }
        .swiper-pagination-bullet {
            width: 8px !important;
            height: 8px !important;
            border-radius: 0 !important;
            background-color: ${theme.colors.gray4} !important;
            opacity: 1 !important;
            margin: 0 4px !important;
            transition: background-color 0.2s ease;

            &.swiper-pagination-bullet-active {
                background-color: white !important;
            }
        }
    }
`;

export const _bannerImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 590px;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
    will-change: transform;

    > a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    ${mediaDown('desktop')} {
        height: 0;
        padding-top: 41.67%;

        @supports (aspect-ratio: 1 / 1) {
            height: auto;
            padding-top: 0;
            aspect-ratio: 2.4 / 1;
        }
    }

    ${mediaDown('tablet')} {
        height: 0;
        padding-top: 55.56%;

        @supports (aspect-ratio: 1 / 1) {
            height: auto;
            padding-top: 0;
            aspect-ratio: 1.8 / 1;
        }
    }

    ${mediaDown('mobile')} {
        padding-top: 100%;

        @supports (aspect-ratio: 1 / 1) {
            padding-top: 0;
            aspect-ratio: 1 / 1;
        }
    }
`;
