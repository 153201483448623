'use client';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/navigation';
import { MainHotBrand } from '@/app/_hook/useGetMainHotbrands';
import { MouseEvent } from 'react';
import { usePostLikeItem } from '@/app/_hook/usePostLikeItem';
import { useQueryClient } from '@tanstack/react-query';
import useIcon from '@/app/_hook/useIcon';
import { useImageShimmer } from '@/app/_hook/useImageShimmer';
// Styles
import { _section, _h2, _inner, _title, _desktopGrid, _mobileSwiper, _brandContainer, _imgWrap, _productList, _productLink, _productItem, _productContent, _productImage, _productInfo, _brand, _name, _priceInfo, _sale, _price, _like } from '@/app/styles/components/main/hotBrands';

interface Props {
    brands: MainHotBrand;
}

const MainHotBrands = ({ brands }: Props) => {
    const { IcLikeStroke, IcLikeFull } = useIcon();
    const router = useRouter();
    const queryClient = useQueryClient();
    const { postLike } = usePostLikeItem();
    const { getBlurDataURL } = useImageShimmer();

    const handleLike = (e: MouseEvent<HTMLDivElement>, productIdx: string) => {
        e.preventDefault();
        postLike(
            { productIdx },
            {
                onSuccess: () => {
                    queryClient.refetchQueries({ queryKey: ['mainhotBrands'] });
                },
            }
        );
    };

    const renderBrandItem = (brand: MainHotBrand['brands'][0], isSwiper = false) => (
        <_brandContainer className={isSwiper ? 'swiper-item' : ''}>
            <_imgWrap onClick={() => router.push(`/brand?partnersIdx=${brand.partnerIdx}`)}>
                {/* <Image src={brand.mainBrandImageUrl} alt="brand" fill priority quality={75} sizes="(max-width: 1024px) 100vw, 50vw" placeholder="blur" blurDataURL={getBlurDataURL(744, 480)} /> */}
                <img src={brand.mainBrandImageUrl} alt="brand" loading="lazy" decoding="async" />
            </_imgWrap>
            <_productList>
                {brand.products.map((item, idx2) => (
                    <_productLink href={`/product/${item.productIdx}`} key={idx2}>
                        <_productItem>
                            <_productContent>
                                <_productImage className="product-img-container-square">
                                    {/* <Image src={item.productImageUrl} alt={item.productName} fill loading="lazy" quality={75} sizes="(max-width: 500px) 64px, 104px" placeholder="blur" blurDataURL={getBlurDataURL(104, 104)} /> */}
                                    <img className="product-img product-img--center" src={item.productImageUrl} alt={item.productName} loading="lazy" decoding="async" />
                                </_productImage>
                                <_productInfo>
                                    <_brand>{item.brandName}</_brand>
                                    <_name>{item.productName}</_name>
                                    <_priceInfo>
                                        {item.productDiscountRate > 0 && <_sale>{item.productDiscountRate}%</_sale>}
                                        <_price>{item.productDiscountAfterPrice.toLocaleString()}원</_price>
                                    </_priceInfo>
                                </_productInfo>
                            </_productContent>

                            <_like onClick={(e) => handleLike(e, item.productIdx)}>
                                {item.isLike ? <IcLikeFull size={30} color="#E91237" /> : <IcLikeStroke size={30} color="#A0A0A0" />}
                                <span>{item.productLikeCount}</span>
                            </_like>
                        </_productItem>
                    </_productLink>
                ))}
            </_productList>
        </_brandContainer>
    );

    return (
        <_section>
            <_h2>{brands.mainHomeHotBrandSectionName}</_h2>
            <_inner>
                <_title>{brands.mainHomeHotBrandSectionName}</_title>

                {/* Desktop View */}
                <_desktopGrid>
                    {brands.brands.map((brand, idx) => (
                        <div key={idx}>{renderBrandItem(brand)}</div>
                    ))}
                </_desktopGrid>

                {/* Mobile View */}
                <_mobileSwiper slidesPerView="auto" spaceBetween={8} className="mobile-only">
                    {brands.brands.map((brand, idx) => (
                        <SwiperSlide key={idx}>{renderBrandItem(brand, true)}</SwiperSlide>
                    ))}
                </_mobileSwiper>
            </_inner>
        </_section>
    );
};

export default MainHotBrands;
