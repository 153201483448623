'use client';
import { _wrapper, _inner, _content, _text, _svg } from '@/app/styles/components/main';
import useIcon from '@/app/_hook/useIcon';

const BandBanner = () => {
    const { IcBandBannerOne, IcBandBannerTwo } = useIcon();
    const count = 15;

    return (
        <_wrapper>
            <_inner>
                <_content>
                    {Array(count)
                        .fill(null)
                        .map((_, idx) => (
                            <div key={idx} style={{ display: 'flex' }}>
                                <_svg viewBox="0 0 43 17">
                                    <IcBandBannerOne size={43} color="white" />
                                </_svg>
                                <_svg viewBox="0 0 202 17">
                                    <IcBandBannerTwo size={202} color="white" />
                                </_svg>
                            </div>
                        ))}
                </_content>
                <_content>
                    {Array(count)
                        .fill(null)
                        .map((_, idx) => (
                            <div key={`clone-${idx}`} style={{ display: 'flex' }}>
                                <_svg viewBox="0 0 43 17">
                                    <IcBandBannerOne size={43} color="white" />
                                </_svg>
                                <_svg viewBox="0 0 202 17">
                                    <IcBandBannerTwo size={202} color="white" />
                                </_svg>
                            </div>
                        ))}
                </_content>
            </_inner>
        </_wrapper>
    );
};

export default BandBanner;
