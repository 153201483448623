import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { Swiper as SwiperType } from 'swiper/types';
import { Swiper } from 'swiper/react';

export const _section = styled.section`
    width: 100%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #e0e0e0;
        z-index: 1;

        ${mediaDown('mobile')} {
            height: 1px;
        }
    }

    ${mediaDown('desktop')} {
        /* padding: 0 120px; */
        /* font-size: 18px; */
    }

    ${mediaDown('tablet')} {
        /* padding: 0 60px; */
    }

    ${mediaDown('mobile')} {
        padding: 40px 0 2px;
    }
`;

export const _visuallyHidden = styled.h2`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
`;
export const _container = styled.div`
    max-width: ${breakpoints.desktop};
    padding: 0 80px 0;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    .mobile-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        width: 100%;

        > div {
            width: 100%; // 각 아이템의 너비를 100%로 설정
            border-right: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }
    }

    ${mediaDown('tablet')} {
        padding: 60px 0 0;
    }

    ${mediaDown('mobile')} {
        padding: 0;

        .mobile-only {
            overflow: visible;
            margin-bottom: -1px;
            width: 100%; // 모바일에서 전체 너비 사용
        }

        .mobile-grid {
            width: 100%; // 모바일에서 전체 너비 사용
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr)); // minmax 추가로 최소 너비 설정
        }
    }

    .mobile-only {
        display: none;
        @media screen and (max-width: 1024px) {
            display: flex;
        }
    }
`;
export const _title = styled.p`
    font-size: 28px;
    line-height: 1.25;
    font-weight: 500;
    margin: 80px 0 24px;

    @media screen and (max-width: 1024px) {
        padding: 0 16px;
        font-size: 18px;
        margin: 0 0 16px;
        font-weight: 500;
    }
`;

export const _productGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); // 4개의 열로 설정
    grid-template-rows: auto; // 자동으로 행 크기 설정 (아이템 개수에 따라 행 수가 결정됨)
    gap: 0; // 간격을 없애서 border가 겹치지 않게 함
    border-left: 1px solid #e0e0e0;
    & > div {
        border-top: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        /* border-left: 1px solid #e0e0e0; */
    }
    /* & > div:first-of-child {
        border-left: 1px solid #e0e0e0;
    } */
    /* 마지막 열 오른쪽 경계 제거 */
    /* & > div:nth-child(4n) {
        border-right: none;
    } */

    /* 두 번째 행의 상단 경계 제거 */
    & > div:nth-of-type(n + 5) {
        border-top: none;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const _mobileSwiper = styled(Swiper)`
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
        width: 100%;

        // 4개일 때 (1행)
        &.single-row {
            .swiper-wrapper {
                display: flex !important;
                /* border-top: 1px solid #e0e0e0; */
                border-bottom: 1px solid #e0e0e0;
            }

            .swiper-slide {
                > div {
                    border-top: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                }

                &:first-of-type > div {
                    border-left: 1px solid #e0e0e0;
                }
            }
        }

        // 5개 이상일 때 (2행)
        &:not(.single-row) {
            .swiper-wrapper {
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-auto-flow: column;
                /* border-top: 1px solid #e0e0e0; */
            }

            .swiper-slide {
                height: calc(100% + 1px) !important;
                border-top: 1px solid #e0e0e0;
                /* margin-top: -1px !important; */

                > div {
                    height: 100%;
                    border-right: 1px solid #e0e0e0;
                    border-bottom: 1px solid #e0e0e0;
                }

                &:first-of-type > div {
                    border-left: 1px solid #e0e0e0;
                }
            }
        }
    }
`;

export const _mobileFixed = styled.div`
    display: none;

    @media screen and (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;

        > div {
            border-right: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }
    }
`;
export const _productItem = styled.div`
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%; // 너비 100% 추가
`;

export const _link = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
`;

export const _imgWrap = styled.div``;

export const _info = styled.div`
    padding: 12px 8px 24px 12px;

    @media screen and (max-width: 1024px) {
        padding: 8px 4px 12px 8px;
    }
`;

export const _infoContent = styled.div`
    text-align: left;
`;

export const _like = styled.button`
    display: flex;
    align-items: center;
    gap: 6px;
    background: none;
    border: none;
    /* padding: 4px; */
    cursor: pointer;
    margin: -4px;
    margin-bottom: 12px;

    > span {
        font-size: 15px;
        color: #787878;
        margin-top: 4px;

        @media screen and (max-width: 1024px) {
            font-size: 13px;
        }
    }

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        gap: 4px;

        > svg {
            width: 24px;
            height: 24px;
        }
    }
`;

export const _brand = styled.div`
    font-size: 15px;
    color: #787878;
    font-weight: 400;
    margin-bottom: 6px;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 4px;
    }
`;

export const _name = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 500;
    }
`;

export const _priceInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const _sale = styled.span`
    color: #4d4dff;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        font-weight: 500;
    }
`;

export const _price = styled.span`
    font-size: 16px;
    font-weight: 600;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        font-weight: 500;
    }
`;
