import { getApi } from "@/api/axios";
import { useQuery } from "@tanstack/react-query";

interface NewHotBrands {
  code: 200;
  message: "신생 브랜드 조회 성공";
  data: NewHotBrand[];
}
export interface NewHotBrand {
  mainHomeNewBrandSectionIdx: string;
  mainHomeNewBrandSectionName: string;
  partnersIdx: string;
  brandName: string;
  brandImageUrl: string;
  brandOneLineIntroduction: string;
}

export const useGetNewHotbrands = () => {
  const { data } = useQuery<NewHotBrands>({
    queryKey: [`newHotBrands`],
    queryFn: () =>
      getApi({
        url: `products/main-home-new-brand-sections`,
      }),
  });
  return { newHotBrands: data?.data };
};
