import { getApi } from '@/api/axios';
import { useQuery } from '@tanstack/react-query';

interface MainSectionList {
    code: 200;
    message: '메인 홈 커스텀 섹션 조회 성공';
    data: MainSection[];
}

interface MainSection {
    mainHomeSectionIdx: string;
    mainHomeSectionName: string;
    products: {
        productIdx: string;
        productImageUrl: string;
        brandName: string;
        productName: string;
        productDiscountRate: number;
        productDiscountAfterPrice: number;
    }[];
}

export const useGetMainSectionList = () => {
    const { data } = useQuery<MainSectionList>({
        queryKey: [`mainSectionList`],
        queryFn: () =>
            getApi({
                url: `products/main-home-sections`,
            }),
    });

    return { mainSectionList: data?.data || [] };
};
