'use client';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { api } from '@/api/axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { _bannerContainer, _bannerImageWrapper } from '@/app/styles/components/main';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

interface BannerResponse {
    data: {
        bannerDestinationUrl: string;
        bannerImageUrl: string;
        createdAt: string;
        deletedAt: string | null;
        idx: string;
        updatedAt: string;
    }[];
}

interface Props {
    imgs: string[];
}

const BannerImageWrapper = styled(_bannerImageWrapper)`
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        inset: 0px;
        object-fit: cover;
        object-position: center center;
        color: transparent;
    }
`;

const MainBanner = ({ imgs }: Props) => {
    const { data: bannerData } = useQuery<BannerResponse>({
        queryKey: ['mainBanner'],
        queryFn: () =>
            api({
                method: 'GET',
                url: 'products/main-banners',
            }),
        staleTime: 1000 * 60 * 5, // 5분 동안 캐시 유지
    });

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (bannerData?.data) {
            setIsInitialized(true);
        }
    }, [bannerData]);

    return (
        <_bannerContainer>
            {isInitialized && bannerData?.data && bannerData.data.length > 0 && (
                <Swiper
                    modules={[Pagination, Autoplay, EffectFade]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={bannerData.data.length > 1}
                    speed={800}
                    effect="fade"
                    fadeEffect={{ crossFade: true }}
                    pagination={{
                        clickable: true,
                        el: '.swiper-pagination',
                    }}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    grabCursor={true}
                    className="mySwiper">
                    {bannerData.data.map((ele, idx) => (
                        <SwiperSlide key={ele.idx}>
                            <BannerImageWrapper className="wrap">
                                <Link href={ele.bannerDestinationUrl} target="_blank">
                                    <img src={ele.bannerImageUrl} alt={`banner-${idx + 1}`} loading={idx === 0 ? 'eager' : 'lazy'} decoding="async" />
                                </Link>
                            </BannerImageWrapper>
                        </SwiperSlide>
                    ))}
                    <div className="swiper-pagination"></div>
                </Swiper>
            )}
        </_bannerContainer>
    );
};

export default MainBanner;
