import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { zIndex } from '@/app/styles/base/zIndex';

export const _categorySection = styled.section`
    width: 100%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #e0e0e0;
        z-index: 1;
    }
    ${mediaDown('mobile')} {
        &::before {
            height: 1px;
        }
    }
`;

export const _categoryGrid = styled.div`
    max-width: ${breakpoints.desktop};
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 24px;
    column-gap: 16px;
    padding: 80px 80px;

    ${mediaDown('tablet')} {
        padding: 60px 60px;
    }

    ${mediaDown('mobile')} {
        padding: 40px 40px;
        row-gap: 24px;
        column-gap: 16px;
    }

    ${mediaDown('smallMobile')} {
        padding: 24px 16px;
        gap: 16px;
    }
`;

export const _categoryItem = styled.article`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const _categoryImageBox = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%; // 131/262 = 0.5 = 50%
    overflow: hidden;
    transition: transform 0.2s ease-in-out;

    ${mediaDown('mobile')} {
        padding-bottom: 100%;
    }
`;

export const _imageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
export const _categoryText = styled.span`
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    color: ${theme.colors.black};

    ${mediaDown('tablet')} {
        font-size: 14px;
    }

    ${mediaDown('smallMobile')} {
        font-size: 13px;
    }
`;
