'use client';
import styled from '@emotion/styled';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import { MouseEvent } from 'react';
import { useImageShimmer } from '@/app/_hook/useImageShimmer';
import { usePostLikeItem } from '@/app/_hook/usePostLikeItem';
import { useQueryClient } from '@tanstack/react-query';
// Icon Hooks
import useIcon from '@/app/_hook/useIcon';

// Styles
import { _section, _visuallyHidden, _container, _title, _productGrid, _mobileSwiper, _productItem, _link, _imgWrap, _info, _infoContent, _like, _brand, _name, _priceInfo, _sale, _price } from '@/app/styles/components/main/recommend';
interface Props {
    items: {
        productIdx: string;
        productImageUrl: string;
        brandName: string;
        productName: string;
        productDiscountRate: number;
        productDiscountAfterPrice: number;
        isLike?: boolean;
        productLikeCount?: number;
    }[];
    title: string;
}
const MainRecomm = ({ items, title }: Props) => {
    const { IcLikeStroke, IcLikeFull } = useIcon();
    const imageShimmer = useImageShimmer();
    const { postLike } = usePostLikeItem();
    const queryClient = useQueryClient();

    const handleLike = (e: MouseEvent, productIdx: string) => {
        e.preventDefault();
        postLike(
            { productIdx },
            {
                onSuccess: () => {
                    queryClient.refetchQueries({ queryKey: ['mainSectionList'] });
                },
            }
        );
    };

    const renderProductItem = (item: Props['items'][0], index: number) => {
        return (
            <_productItem>
                <_link href={`/product/${item.productIdx}`}>
                    <_imgWrap className="product-img-container">
                        <img className="product-img product-img--center" src={item.productImageUrl} alt={item.productName || 'product'} loading="lazy" decoding="async" />
                    </_imgWrap>
                    <_info>
                        <_infoContent>
                            <_like onClick={(e) => handleLike(e, item.productIdx)}>
                                {item.isLike ? <IcLikeFull size={30} color="#E91237" /> : <IcLikeStroke size={30} color="#A0A0A0" />}
                                <span>{item.productLikeCount}</span>
                            </_like>
                            <_brand>{item.brandName}</_brand>
                            <_name>{item.productName}</_name>
                            <_priceInfo>
                                {item.productDiscountRate ? <_sale>{item.productDiscountRate}%</_sale> : null}
                                <_price>{item.productDiscountAfterPrice.toLocaleString()}원</_price>
                            </_priceInfo>
                        </_infoContent>
                    </_info>
                </_link>
            </_productItem>
        );
    };

    return (
        <_section>
            <_visuallyHidden>추천 상품</_visuallyHidden>
            <_container>
                <_title>{title}</_title>

                {/* PC 뷰 */}
                <_productGrid>
                    {items.map((item, idx) => (
                        <div key={idx}>{renderProductItem(item, idx)}</div>
                    ))}
                </_productGrid>

                {/* 모바일 뷰 */}
                {items.length <= 3 ? (
                    <div className="mobile-only mobile-grid">
                        {items.map((item, idx) => (
                            <div key={idx}>{renderProductItem(item, idx)}</div>
                        ))}
                    </div>
                ) : (
                    <_mobileSwiper
                        modules={[Grid]}
                        grid={
                            items.length === 4 // 현재 코드: 4개일 때 1행, 5개 이상일 때 2행으로 표시
                                ? undefined
                                : {
                                      rows: 2,
                                      fill: 'row',
                                  }
                        }
                        slidesPerView={3.1} // 현재 코드: 모바일에서 3.1개씩 보이도록 설정
                        spaceBetween={0}
                        className={`mobile-only ${items.length === 4 ? 'single-row' : ''}`}>
                        {items.map((item, idx) => (
                            <SwiperSlide key={idx}>{renderProductItem(item, idx)}</SwiperSlide>
                        ))}
                    </_mobileSwiper>
                )}
            </_container>
        </_section>
    );
};

export default MainRecomm;
