'use client';
import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/navigation';
import { NewHotBrand } from '@/app/_hook/useGetNewHotbrands';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import useIcon from '@/app/_hook/useIcon';
import { useState } from 'react';

interface Props {
    data: NewHotBrand[] | undefined;
}

const MainNewBrand = ({ data }: Props) => {
    const router = useRouter();
    const { IcBack, IcNext } = useIcon();
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const [swiperInstance, setSwiperInstance] = useState<any>(null);
    const [isHovered, setIsHovered] = useState(false);

    if (!data || data.length === 0 || data.every((item) => item === null)) {
        return null;
    }

    const handleBrandClick = (partnersIdx: string, brandName: string) => {
        router.push(`/brand?partnersIdx=${partnersIdx}&brand=${brandName}`);
    };

    const handleSlideChange = () => {
        if (!swiperInstance) return;
        setIsStart(swiperInstance.isBeginning);
        setIsEnd(swiperInstance.isEnd);
    };

    const renderBrandCard = (item: NewHotBrand, isPriority = false) => (
        <_brandCard onClick={() => handleBrandClick(item.partnersIdx, item.brandName)}>
            <_imageContainer className="product-img-container">
                <img className="product-img" src={item.brandImageUrl} alt={item.brandName || 'brand'} loading="lazy" decoding="async" />
            </_imageContainer>
            <_brandInfo>
                <_brandName>{item.brandName || ''}</_brandName>
                <_brandDescription>{item.brandOneLineIntroduction || ''}</_brandDescription>
            </_brandInfo>
        </_brandCard>
    );

    const showNavigationButtons = data.length > 3;

    return (
        <_section onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <_inner>
                <_title>{data?.[0]?.mainHomeNewBrandSectionName ?? 'ECF 신생브랜드'}</_title>
                <_swiperWrapper className={`${data.length <= 3 ? 'few-slides' : ''}`}>
                    <_swiperContainer className={`${data.length === 1 ? 'single-slide' : ''}`}>
                        <Swiper
                            className="brand-swiper"
                            slidesPerView="auto"
                            spaceBetween={8}
                            observer={true}
                            observeParents={true}
                            onSwiper={setSwiperInstance}
                            onSlideChange={handleSlideChange}
                            breakpoints={{
                                1024: {
                                    slidesPerView: data.length < 3 ? data.length : 3,
                                    spaceBetween: 24,
                                    slidesOffsetBefore: 0,
                                },
                                320: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 8, // 슬라이드 간격만 조정
                                    slidesOffsetBefore: 0, // 시작 위치 offset 제거
                                },
                            }}>
                            {data.filter(Boolean).map((item, idx) => (
                                <SwiperSlide key={idx}>{renderBrandCard(item, idx === 0)}</SwiperSlide>
                            ))}
                        </Swiper>
                    </_swiperContainer>
                    {showNavigationButtons && !isStart && (
                        <_prevButton onClick={() => swiperInstance?.slidePrev()}>
                            <IcBack size={24} color="#000" />
                        </_prevButton>
                    )}
                    {showNavigationButtons && !isEnd && (
                        <_nextButton onClick={() => swiperInstance?.slideNext()}>
                            <IcNext size={24} color="#000" />
                        </_nextButton>
                    )}
                </_swiperWrapper>
            </_inner>
        </_section>
    );
};

const _section = styled.section`
    width: 100%;
    position: relative;
    padding: 80px 0;

    ${mediaDown('tablet')} {
        padding: 40px 0;
    }

    /* ${mediaDown('mobile')} {
        padding: 24px 0;
    } */
`;

const _inner = styled.div`
    max-width: ${breakpoints.desktop};
    margin: 0 auto;
    position: relative;
    padding: 0 80px 0;

    ${mediaDown('mobile')} {
        padding: 0; // 모바일에서는 패딩 제거
    }
`;

const _title = styled.p`
    font-size: 28px;
    line-height: 1.25;
    font-weight: 500;
    margin-bottom: 24px;

    ${mediaDown('mobile')} {
        padding: 0 16px;
        font-size: 18px;
        margin: 0 0 16px;
        font-weight: 500;
    }
`;

const _swiperContainer = styled.div`
    width: 100%;

    .swiper {
        margin: 0; // 모든 기본 마진 제거
        padding: 0; // 모든 기본 패딩 제거
    }

    .swiper-wrapper {
        justify-content: flex-start;
    }

    .swiper-slide {
        height: auto;

        @media screen and (min-width: 768px) {
            /* width: 33.333% !important; */
            width: calc((100% - 48px) / 3) !important;
        }

        ${mediaDown('mobile')} {
            width: 45% !important;

            &:first-of-type {
                margin-left: 0;
                margin-left: 16px; // 첫 번째 슬라이드에만 왼쪽 여백
            }

            &:last-of-type {
                margin-right: 16px; // 마지막 슬라이드에만 오른쪽 여백
            }
        }
    }

    &.single-slide {
        .swiper-slide {
            ${mediaDown('mobile')} {
                width: 45% !important;
                margin-left: 16px;
                // 단일 슬라이드일 때도 동일한 여백 유지
            }
        }
    }
`;
const _brandCard = styled.div`
    cursor: pointer;
`;

const _imageContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .product-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${mediaDown('mobile')} {
        margin-bottom: 12px;
    }
`;

const _brandInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const _brandName = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    ${mediaDown('mobile')} {
        font-size: 14px;
        line-height: 20px;
    }
`;

const _brandDescription = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000;

    ${mediaDown('mobile')} {
        color: #787878;
    }
`;
const _swiperWrapper = styled.div`
    position: relative;
    width: 100%;

    &.few-slides {
        button {
            display: none;
        }
    }

    &:hover {
        button {
            opacity: 1;
        }
    }
`;

const _navigationButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        background: rgba(255, 255, 255, 1);
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const _prevButton = styled(_navigationButton)`
    left: -20px;
`;

const _nextButton = styled(_navigationButton)`
    right: -20px;
`;
export default MainNewBrand;
