import { getApi } from '@/api/axios';
import { useQuery } from '@tanstack/react-query';

interface MainHotBrands {
  code: 200;
  message: '메인 홈 커스텀 섹션 조회 성공';
  data: MainHotBrand[];
}

export interface MainHotBrand {
  mainHomeHotBrandSectionIdx: string;
  mainHomeHotBrandSectionName: string;
  brands: {
    partnerIdx: string;
    mainBrandImageUrl: string;
    products: {
      productIdx: string;
      productImageUrl: string;
      brandName: string;
      productName: string;
      productDiscountRate: number;
      productDiscountAfterPrice: number;
      productLikeCount: number;
      isLike: boolean;
    }[];
  }[];
}

export const useGetMainHotbrands = () => {
  const { data } = useQuery<MainHotBrands>({
    queryKey: [`mainhotBrands`],
    queryFn: () =>
      getApi({
        url: `products/main-home-hot-brand-sections`,
      }),
  });

  return { mainhotBrands: data?.data[0] };
};
