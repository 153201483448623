'use client';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useIcon from '@/app/_hook/useIcon';

// Styles
import { _counterWrapper, _headerWrapper, _title, _daysLeft, _timerWrapper, _timeText } from '@/app/styles/components/main/timeSale';

dayjs.extend(duration);

interface Props {
    end: string;
}

interface TimeLeft {
    days: number;
    hours: string;
    minutes: string;
    seconds: string;
}

const MainTimeCounter = ({ end }: Props) => {
    const { IcTime } = useIcon();
    const endTime = useMemo(() => dayjs(end), [end]);

    const calculateTimeLeft = useCallback(() => {
        const now = dayjs();
        const difference = endTime.diff(now);

        if (difference < 0) {
            return {
                days: 0,
                hours: '00',
                minutes: '00',
                seconds: '00',
            };
        }

        const timeDuration = dayjs.duration(difference);
        const days = Math.floor(timeDuration.asDays());
        const hours = String(timeDuration.hours()).padStart(2, '0');
        const minutes = String(timeDuration.minutes()).padStart(2, '0');
        const seconds = String(timeDuration.seconds()).padStart(2, '0');

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }, [endTime]);

    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft);

    useEffect(() => {
        setTimeLeft(calculateTimeLeft());

        const interval = setInterval(() => {
            const newTime = calculateTimeLeft();
            if (newTime.hours === '00' && newTime.minutes === '00' && newTime.seconds === '00') {
                clearInterval(interval);
            }
            setTimeLeft(newTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [calculateTimeLeft]);

    return (
        <_counterWrapper>
            <_headerWrapper>
                {timeLeft.days > 0 && <_title>TIME SALE</_title>}
                {timeLeft.days > 0 && <_daysLeft>D-{timeLeft.days}</_daysLeft>}
            </_headerWrapper>
            <_timerWrapper>
                <IcTime size={48} color="#000" />
                <_timeText>
                    {timeLeft.hours}
                    <span className="colon">:</span>
                    {timeLeft.minutes}
                    <span className="colon">:</span>
                    {timeLeft.seconds}
                </_timeText>
            </_timerWrapper>
        </_counterWrapper>
    );
};

export default MainTimeCounter;
