import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { Swiper as SwiperType } from 'swiper/types';
import { Swiper } from 'swiper/react';

export const _section = styled.section`
    width: 100%;
    padding: 80px 0 20px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #e0e0e0;
        z-index: 1;

        ${mediaDown('mobile')} {
            height: 1px;
        }
    }

    ${mediaDown('tablet')} {
        padding: 80px 0;
    }

    ${mediaDown('mobile')} {
        padding: 40px 0 25px;
    }
`;

export const _inner = styled.div`
    max-width: ${breakpoints.desktop};
    padding: 0 80px 0;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    ${mediaDown('tablet')} {
        padding: 0 60px 0;
    }

    ${mediaDown('mobile')} {
        row-gap: 24px;
        column-gap: 16px;

        padding: 0;
        .mobile-only {
            display: block;
        }
    }

    ${mediaDown('smallMobile')} {
        padding: 24px 0 0;
    }
`;

export const _h2 = styled.h2`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
`;

export const _title = styled.p`
    font-size: 28px;
    line-height: 1.25;
    font-weight: 500;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
        padding: 0 16px;
        font-size: 18px;
        margin: 0 0 16px;
        font-weight: 500;
    }
`;

export const _desktopGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    @media screen and (max-width: 1024px) {
        gap: 12px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const _mobileSwiper = styled(Swiper)`
    display: none;

    .swiper-slide {
        width: calc(100vw - 32px) !important;
        height: auto !important;
    }
`;

export const _brandContainer = styled.div`
    position: relative;
    background: #fff;
    height: 100%;
`;

export const _imgWrap = styled.div`
    position: relative;
    width: 100%;

    /* PC 비율: 744x480 = 64.516% */
    &::before {
        content: '';
        display: block;
        padding-top: 64.516%;
    }

    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: 1024px) {
        /* 모바일 비율: 360x404 = 112.222% */
        &::before {
            padding-top: 112.222%;
        }
    }
`;

export const _productList = styled.div`
    width: calc(100% - 46px);
    margin: -48px 0px 0 46px;
    padding: 60px 40px;
    background: #fff;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1024px) {
        width: calc(100% - 16px);
        margin: -124px 0px 0 16px;
        padding: 24px 20px;
        height: auto;
        min-height: 124px;
    }
`;

export const _productLink = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
    @media screen and (max-width: 1024px) {
        &:not(:last-child) {
            margin-bottom: 22px;
        }
    }
`;

export const _productItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

export const _productContent = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
`;

export const _productImage = styled.div`
    position: relative;
    width: 104px;
    height: auto;
    flex-shrink: 0;

    @media screen and (max-width: 500px) {
        width: 64px;
        height: auto;
    }
`;

export const _productInfo = styled.div`
    flex: 1;
    min-width: 0;
    margin-left: 16px;
`;

export const _brand = styled.div`
    font-size: 15px;
    color: #787878;
    font-weight: 400;
    margin-bottom: 6px;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 4px;
    }
`;

export const _name = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 500;
    }
`;

export const _priceInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const _sale = styled.span`
    color: #4d4dff;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        font-weight: 500;
    }
`;

export const _price = styled.span`
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 1024px) {
        font-size: 13px;
        font-weight: 500;
    }
`;

export const _like = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding-bottom: 4px;

    > span {
        font-size: 15px;
        color: #787878;
        /* margin-top: 4px; */
        text-align: center;

        @media screen and (max-width: 1024px) {
            font-size: 13px;
        }
    }

    @media screen and (max-width: 1024px) {
        > svg {
            width: 24px;
            height: 24px;
        }
    }
`;
