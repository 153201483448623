'use client';
import MainTimeCounter from './MainTimeCounter';
import dayjs from 'dayjs';
import { PrListData } from '../../page';
import { MouseEvent, useState } from 'react';
import { useRouter } from 'next/navigation';
// 1. API 관련 import
import { usePostLikeItem } from '@/app/_hook/usePostLikeItem';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@/api/axios';

// Icon Hooks
import useIcon from '@/app/_hook/useIcon';

// Styles
import { _section, _borderWrapper, _timeSaleSection, _screenReaderTitle, _contentWrapper, _titleArea, _titleText, _iconWrapper, _titleLink, _productGrid, _productItem, _discountNumber, _firstDigit, _secondDigit, _discountBageWrapper, _percentSign, _productImageContainer, _productInfo } from '@/app/styles/components/main/timeSale';

import { _inner, _info, _infoContent, _brand, _name, _priceInfo, _sale, _price, _like } from '@/app/styles/layout/container';

interface Props {
    data: PrListData | undefined;
    likeFunc: (idx: string) => void;
}

const MainTimeSale = ({ data: prlistData, likeFunc }: Props) => {
    const router = useRouter();
    const { IcLikeStroke, IcLikeFull, IcNext } = useIcon();
    const end = prlistData?.data?.result?.timeEventEndDate ? dayjs(dayjs(prlistData?.data?.result?.timeEventEndDate).format('YYYY-MM-DD') + prlistData?.data?.result?.timeEventEndTime).format('YYYY-MM-DD HH:mm') : undefined;

    return (
        <>
            <_section>
                {end && prlistData?.data.productTotalCount ? (
                    <_borderWrapper>
                        <_timeSaleSection>
                            <_screenReaderTitle>타임세일</_screenReaderTitle>
                            <_contentWrapper>
                                <_titleLink href={`/prlist?type=sale&page=1`}>
                                    <_titleArea>
                                        <_titleText>구매를 서두르세요</_titleText>
                                        <_iconWrapper aria-hidden="true">
                                            {/* <span>더보기</span> */}
                                            <IcNext size={36} color="#000" />
                                        </_iconWrapper>
                                    </_titleArea>
                                </_titleLink>
                                <MainTimeCounter end={end} />
                                <_productGrid>
                                    {prlistData?.data?.result?.products?.map((item, idx) => {
                                        return (
                                            <_productItem key={idx}>
                                                <_productImageContainer className="product-img-container" onClick={() => router.push(`/product/${item.idx}?productType=time`)}>
                                                    <img className="product-img product-img--center" src={item.productImageUrl} alt={item.productName} loading="lazy" decoding="async" />
                                                </_productImageContainer>
                                                <_info>
                                                    <_infoContent>
                                                        {/* onClick={(e) => handleLike(e, item.idx)} */}
                                                        <_like
                                                            onClick={() => {
                                                                likeFunc(item.idx);
                                                            }}>
                                                            {item?.liked?.isLike ? <IcLikeFull size={30} color="#E91237" /> : <IcLikeStroke size={30} color="#A0A0A0" />}
                                                            <span>{item.productLikeCount}</span>
                                                        </_like>
                                                        <_brand>{item.productBrand}</_brand>
                                                        <_name onClick={() => router.push(`/product/${item.idx}?productType=time`)}>{item.productName}</_name>
                                                        <_priceInfo>
                                                            <_price>{(item.productPrice - item.productPrice * (item.discountRate / 100)).toLocaleString()}원</_price>
                                                        </_priceInfo>
                                                    </_infoContent>
                                                </_info>

                                                <_discountBageWrapper>
                                                    <_discountNumber>
                                                        <_firstDigit>{String(item.discountRate)[0]}</_firstDigit>
                                                        <_secondDigit>{String(item.discountRate)[1]}</_secondDigit>
                                                    </_discountNumber>
                                                    <_percentSign>%</_percentSign>
                                                </_discountBageWrapper>
                                            </_productItem>
                                        );
                                    })}
                                </_productGrid>
                            </_contentWrapper>
                        </_timeSaleSection>
                    </_borderWrapper>
                ) : (
                    <></>
                )}
            </_section>
        </>
    );
};

export default MainTimeSale;
