'use client';
import styled from '@emotion/styled';
import MainBanner from './_component/main/MainBanner';
import MainTimeSale from './_component/main/MainTimeSale';
// import MainTimeSale, { mockPrListData } from './_component/main/MainTimeSale';
import MainRecomm from './_component/main/MainRecomm';
import MainNewBrand from './_component/main/MainNewBrand';
import { theme } from '../styles/theme';
import { useGetMainSectionList } from '../_hook/useGetMainSectionList';
import { useGetMainHotbrands } from '../_hook/useGetMainHotbrands';
import MainHotBrands from './_component/main/MainHotBrands';
import { useGetNewHotbrands } from '../_hook/useGetNewHotbrands';
import { useQuery } from '@tanstack/react-query';
import { api } from '@/api/axios';
import MainCate from './_component/main/MainCate';
import BandBanner from './_component/main/BandBanner';
import { useContext } from 'react';
import { ModalContextDispatch } from '../_common/modal/modalProvider/ModalContext';
import { usePostLikeItem } from '../_hook/usePostLikeItem';
import { useRouter } from 'next/navigation';
import { hasCookie } from '@/api/cookie';
import ModalTwoBtns from '../_common/modal/ModalTwoBtns';

export type TimeEventStatus = 'UPCOMING' | 'RECEPTION_ONGOING' | 'RECEPTION_CLOSED' | 'TIME_EVENT_ONGOING' | 'TIME_EVENT_CLOSED';
export interface PrListData {
    data: {
        productTotalCount: number;
        result: {
            idx: string;
            createdAt: string;
            timeEventStatus: TimeEventStatus;
            timeEventTitle: string;
            receptionStartDate: string;
            receptionEndDate: string;
            timeEventStartDate: string;
            timeEventEndDate: string;
            timeEventStartTime: string;
            timeEventEndTime: string;
            timeEventDescription: string;
            TimeEventParticipant: any[];
            products: {
                idx: string;
                productImageUrl: string;
                productName: string;
                productPrice: number;
                productLikeCount: number;
                productBrand: string;
                discountRate: number;
                discountAmount: number;
                discountType: string;
                salePrice: number;
                liked?: {
                    idx: string;
                    createdAt: string;
                    updatedAt: string;
                    isLike: boolean;
                    customerIdx: string;
                    productIdx: string;
                };
            }[];
        };
    };
}

export default function MainPage() {
    const router = useRouter();
    const { mainSectionList } = useGetMainSectionList();
    const { mainhotBrands } = useGetMainHotbrands();
    const { newHotBrands } = useGetNewHotbrands();
    const {
        data: prlistData,
        refetch,
        isLoading,
    } = useQuery<PrListData>({
        queryKey: ['prlist_main'],
        queryFn: () =>
            api({
                method: 'GET',
                url: '/time-event',
                params: {
                    page: 1,
                    limit: 3,
                },
            }),
    });

    const { addModal, clearModal } = useContext(ModalContextDispatch);

    const { postLike } = usePostLikeItem();

    const likeFunc = (idx: string) => {
        const isUser = hasCookie('accessToken');

        if (!isUser) {
            addModal(
                <ModalTwoBtns
                    content={`로그인이 필요합니다\n로그인 하시겠습니까?`}
                    onClickBtn={() => {
                        clearModal();
                        router.push('/login');
                    }}
                    onClickCancel={clearModal}
                    cancelText="취소"
                    BtnText="이동"
                />
            );
        } else {
            postLike(
                { productIdx: idx },
                {
                    onSuccess: (res) => {
                        console.log(res);
                        // prListRefetch();
                        refetch();
                    },
                    onError: (err) => {
                        console.log(err);
                    },
                }
            );
        }
    };

    return (
        <_inner>
            {/* 배너 */}
            <MainBanner imgs={['', '', '']} />
            <BandBanner />
            <MainCate />
            {/* 타임 특가 */}
            <MainTimeSale data={prlistData} likeFunc={likeFunc} />
            {/* <MainTimeSale data={mockPrListData} /> */}
            {/* 메인 섹션 */}
            {mainSectionList.map((ele, idx) => {
                return <MainRecomm key={idx} items={ele.products.filter((product) => product !== null)} title={ele.mainHomeSectionName} />;
            })}
            {/* 핫한 브랜드 */}
            {mainhotBrands && <MainHotBrands brands={mainhotBrands} />}
            {/* 신생 브랜드 */}
            {newHotBrands && <MainNewBrand data={newHotBrands} />}
        </_inner>
    );
}

const _inner = styled.div`
    width: 100%;
`;
