import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';

const scroll = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
`;

export const _svg = styled.svg`
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0 200px;
    fill: ${theme.colors.white};

    ${mediaDown('desktop')} {
        padding: 0 120px;
    }

    ${mediaDown('tablet')} {
        padding: 0 80px;
    }

    ${mediaDown('mobile')} {
        height: 16px;
        padding: 0 60px;
    }
`;

export const _wrapper = styled.div`
    width: 100%;
    /* height: 46px; */
    background-color: ${theme.colors.black};
    position: relative;
    overflow: hidden;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.1); */

    &::before,
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 60px;
        z-index: 2;
        top: 0;
        pointer-events: none;

        ${mediaDown('mobile')} {
            width: 30px;
        }
    }

    &::before {
        left: 0;
        background: linear-gradient(to right, ${theme.colors.black}, transparent);
    }

    &::after {
        right: 0;
        background: linear-gradient(to left, ${theme.colors.black}, transparent);
    }
`;

export const _inner = styled.div`
    display: flex;
    width: fit-content;
    height: 44px;
    animation: ${scroll} 200s linear infinite;
    transform: translateZ(0);

    ${mediaDown('mobile')} {
        height: 40px;
        animation: ${scroll} 180s linear infinite;
    }
`;

export const _content = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const _text = styled.span`
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: 24px;
    line-height: 1.2;
    color: ${theme.colors.white};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 0 200px;

    ${mediaDown('desktop')} {
        padding: 0 120px;
    }

    ${mediaDown('tablet')} {
        padding: 0 80px;
    }

    ${mediaDown('mobile')} {
        /* font-weight: ${theme.fontWeight.normal}; */
        font-size: 20px;
        padding: 0 60px;
    }
`;
