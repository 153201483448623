import styled from '@emotion/styled';
import { theme } from '@/app/styles/theme';
import { mediaUp, mediaDown } from '@/app/styles/utils/responsive';
import { breakpoints } from '@/app/styles/base/breakpoints';
import { zIndex } from '@/app/styles/base/zIndex';
import Link from 'next/link';

/** 
타임 세일 - MainTimeSale
*/
export const _section = styled.section`
    width: 100%;
    position: relative;

    ${mediaDown('mobile')} {
        padding: 40px 0 2px;
    }
`;
export const _borderWrapper = styled.div`
    width: 100%;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #e0e0e0;
        z-index: 1;

        ${mediaDown('mobile')} {
            height: 1px;
        }
    }
`;
export const _timeSaleSection = styled.section`
    width: 100%;
    position: relative;
    padding: 80px 80px 0;
    background-color: #fff;
    max-width: ${breakpoints.desktop};
    margin: 0 auto;

    /* @media screen and (max-width: 1024px) {
        padding: 40px 0 2px;
    } */
    /* ${mediaDown('tablet')} {
        padding: 40px 0 0;
    } */

    ${mediaDown('tablet')} {
        padding: 60px 60px 0;
    }

    ${mediaDown('mobile')} {
        padding: 40px 0;
        row-gap: 24px;
        column-gap: 16px;
    }

    ${mediaDown('smallMobile')} {
        padding: 24px 0 0;
    }
`;

export const _screenReaderTitle = styled.h2`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const _contentWrapper = styled.div`
    max-width: ${theme.breakpoints.largeDesktop};
    width: 100%;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    /* padding: 0 20px; */

    ${mediaDown('desktop')} {
        /* padding: 0px 20px; */
    }

    ${mediaDown('tablet')} {
        /* padding: 0px 20px; */
    }

    ${mediaDown('mobile')} {
        padding: 0;
    }
`;
export const _titleLink = styled(Link)`
    display: block;
    width: 100%;
    text-decoration: none;
    color: inherit;

    ${mediaDown('mobile')} {
        padding: 0 16px;
    }
`;

export const _titleArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: ${theme.fontSize.xxlarge};
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.colors.black};
    line-height: 1.5;

    /* padding: 0px 16px; */

    ${mediaDown('mobile')} {
        font-size: ${theme.fontSize.large};
        margin-bottom: 12px;
        justify-content: space-between;
        width: 100%;
    }
`;

export const _titleText = styled.p`
    font-size: ${theme.fontSize.xlarge};
    font-weight: ${theme.fontWeight.medium};
    font-family: ${theme.fontFamily.body};
    letter-spacing: -0.015em;
    white-space: nowrap;
    margin: 0;

    ${mediaDown('mobile')} {
        font-size: ${theme.fontSize.medium};
    }
`;

export const _iconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    transition: transform 0.3s ease;
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeight.semiBold};
    /* line-height: 1.1;
    border-bottom: 1px solid ${theme.colors.primary}; */
    ${mediaDown('mobile')} {
        > svg {
            width: 24px;
            height: 24px;
        }
    }
`;

export const _productGrid = styled.ul`
    display: flex;
    width: 100%;
    margin: 26px 0 0;
    border-top: 1px solid ${theme.colors.textDarker[50]};
    list-style: none;
    padding: 0;
    gap: 0;

    /* 상품이 2개일 때 3개 공간 유지 */
    &:has(li:nth-of-type(2):last-of-type)::after {
        content: '';
        flex: 1;
        min-width: calc(100% / 3);
        display: block;
        border-left: 1px solid ${theme.colors.textDarker[50]};
    }

    ${mediaDown('mobile')} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-top: none;
        margin: 0;
        gap: 1px;
        background-color: #fff;

        &::after {
            display: none;
        }
    }
`;

export const _productItem = styled.li`
    position: relative;
    flex: 0 0 auto;
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    box-sizing: content-box;
    padding: 0 0 40px;
    background-color: #fff;
    /* 1개 상품일 때 */
    &:first-of-type:last-of-type {
        border-right: 1px solid ${theme.colors.textDarker[50]};
    }

    /* 2개 상품일 때 두 번째 상품 */
    &:nth-of-type(2):last-of-type {
        border-left: 1px solid ${theme.colors.textDarker[50]};
        border-right: none;
    }

    /* 3개 상품일 때 중간 상품 */
    &:nth-of-type(2):not(:last-of-type) {
        border-left: 1px solid ${theme.colors.textDarker[50]};
        border-right: 1px solid ${theme.colors.textDarker[50]};

        ${mediaDown('mobile')} {
            border-left: 0;
        }
    }

    /* 모바일 스타일 */
    ${mediaDown('mobile')} {
        width: 100%;
        padding: 0;

        /* 1개 상품일 때 border 제거 */
        &:first-of-type:last-of-type {
            border-right: none;
        }

        /* 첫번째 상품 */
        &:first-of-type {
            grid-column: 1 / -1;

            /* ${mediaDown('mobile')} {
                span:first-of-type {
                    font-size: 24px;
                }
                span:nth-of-type(2) {
                    font-size: 16px;
                    color: #787878;
                }
                span:nth-of-type(3) {
                    font-size: 16px;
                }
            } */
        }

        /* 첫번째 상품 (2개 이상일 때) */
        &:first-of-type:not(:last-of-type) {
            border-bottom: 1px solid ${theme.colors.textDarker[50]};
        }

        /* 두번째 상품 */
        &:nth-of-type(2) {
            border-left: none;
        }

        /* 2개 이상일 때 small card */
        &:nth-of-type(n + 2) {
            display: block;
        }
    }

    a {
        display: block;
        text-decoration: none;
        color: inherit;
    }
`;

export const _productImageContainer = styled.div`
    /* margin: 124px auto 116px; */
    > img {
        /* padding: 0 20px 0; */
        /* padding: 0px 16px 0; */
    }
    /* ${mediaDown('desktop')} {
        width: 92%;
        height: 92%;
        margin: 80px auto 32px;
    }

    ${mediaDown('tablet')} {
        width: 100%;
        height: 100%;
        margin: 0;

        &:hover {
            transform: none;
        }
    } */
`;

// export const _aspectRatioBox = styled.div`
//     padding-top: 100%;
// `;

export const _productInfo = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0 24px;
    text-align: left;

    .brandName {
        /* display: none; */
        font-weight: 500;
        font-size: 16px;
        color: #000;
    }

    span {
        display: block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        &:nth-of-type(2) {
            font-size: 14px;
            line-height: 20px;
            margin-top: 4px;
            margin-bottom: 8px;
            font-weight: 400;
            ${mediaDown('mobile')} {
                /* 첫번째 상품 */
                &:first-of-type {
                    color: #787878;
                }
            }
        }

        &:nth-last-of-type {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-top: 4px;
        }
    }

    ${mediaDown('tablet')} {
        padding: 16px;
    }
`;

export const _discountBageWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: flex-start;
    color: ${theme.colors.black};
    font-family: ${theme.fontFamily.en};
    font-weight: ${theme.fontWeight.medium};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;

    /* z-index: ${theme.zIndex.auto}; */

    ${mediaDown('mobile')} {
        top: 0px;
        left: 0px;
    }
`;

export const _discountNumber = styled.div`
    display: flex;
    align-items: flex-start;
    line-height: 1;
    font-family: ${theme.fontFamily.en};
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: -1px;
`;

export const _firstDigit = styled.span`
    display: inline-block;
    font-size: ${theme.fontSize.plus6};
    font-family: ${theme.fontFamily.en};
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: -1px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    li:first-of-type & {
        font-size: ${theme.fontSize.plus8};
        transform: translate3d(0, -12px, 0);

        ${mediaDown('tablet')} {
            font-size: ${theme.fontSize.plus5};
            transform: translate3d(0, -6px, 0);
        }
    }
    ${mediaDown('tablet')} {
        font-size: ${theme.fontSize.plus5};
    }
    ${mediaDown('mobile')} {
        font-size: 40px;

        li:first-of-type & {
            font-size: 80px;
        }
    }
`;

export const _secondDigit = styled.span`
    display: inline-block;
    font-size: ${theme.fontSize.plus6};
    font-family: ${theme.fontFamily.en};
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: -1px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    li:first-of-type & {
        font-size: ${theme.fontSize.plus8};
        transform: translate3d(-12px, 21px, 0);

        ${mediaDown('tablet')} {
            font-size: ${theme.fontSize.plus5};
        }
    }
    ${mediaDown('tablet')} {
        font-size: ${theme.fontSize.plus5};
    }
    ${mediaDown('mobile')} {
        font-size: 40px;

        li:first-of-type & {
            font-size: 80px;
            transform: translate3d(-2px, 15px, 0);
        }
    }
`;

export const _percentSign = styled.span`
    display: inline-block;
    font-size: ${theme.fontSize.plus6};
    font-weight: ${theme.fontWeight.thin};
    margin-left: 4px;
    line-height: 1;
    transform: translate3d(0, 2px, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    user-select: none;
    -webkit-user-select: none;

    li:first-of-type & {
        font-size: ${theme.fontSize.plus5};
        transform: translate3d(-5px, -2px, 0);
        /* margin-left: -3px; */

        ${mediaDown('tablet')} {
            font-size: ${theme.fontSize.plus4};
            transform: translate3d(0, -11px, 0);
            margin-left: -1px;
        }
    }

    ${mediaDown('tablet')} {
        font-size: ${theme.fontSize.plus3};
    }

    ${mediaDown('mobile')} {
        font-size: 32px;
        transform: translate3d(0, 5px, 0);

        li:first-of-type & {
            /* font-size: 60px; */
            transform: translate3d(0, -3px, 0);
            margin-left: -1px;
        }
    }
`;

/** 
타임 카운트 - MainTimeCounter
*/

export const _counterWrapper = styled.div`
    font-family: ${theme.fontFamily.en};
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 20px;
    margin-bottom: 26px;
    /* padding: 0px 16px; */

    ${mediaDown('mobile')} {
        gap: 2px;
        margin-top: 8px;
        margin-bottom: 24px;
        padding: 0px 16px;
    }
`;

export const _headerWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-family: ${theme.fontFamily.en};
    /* padding-left: 16px; */

    ${mediaDown('mobile')} {
        column-gap: 4px;
    }
`;

export const _title = styled.span`
    color: ${theme.colors.primary};
    font-family: ${theme.fontFamily.en};
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.xlarge};
    margin-right: 8px;

    ${mediaDown('mobile')} {
        font-size: ${theme.fontSize.medium};
    }
`;

export const _daysLeft = styled.span`
    color: ${theme.colors.black};
    font-size: ${theme.fontSize.xlarge};
    font-family: ${theme.fontFamily.en};
    font-weight: ${theme.fontWeight.medium};

    ${mediaDown('mobile')} {
        font-size: ${theme.fontSize.medium};
    }
`;

export const _timerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
        width: 48px;
        height: 48px;
    }

    ${mediaDown('mobile')} {
        /* padding-left: 16px; */
        > svg {
            width: 30px;
            height: 30px;
            /* transform: translate(-5px, -1px); */
        }
    }
`;

export const _timeText = styled.p`
    font-family: ${theme.fontFamily.en};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: ${theme.fontSize.plus4};
    /* line-height: ${theme.lineHeight.xs}; */
    line-height: 1.1;
    font-weight: ${theme.fontWeight.medium};
    margin-left: 8px;
    color: #000;

    /* 숫자를 위한 스타일 */
    .number {
        display: inline-block;
        line-height: 1;
        height: 50px;
        display: flex;
        align-items: center;
    }

    /* 콜론을 위한 스타일 */
    .colon {
        display: inline-flex;
        align-items: center;
        height: 50px;
        line-height: 1;
        padding: 0 2px; /* 선택적 간격 조정 */
        transform: translateY(-2px);
        margin-left: 1px;
        margin-right: 1px;
    }

    ${mediaDown('mobile')} {
        white-space: pre-line;
        font-size: ${theme.fontSize.plus2};
        font-weight: ${theme.fontWeight.medium};
        line-height: 1;
        margin-left: 4px;
    }
    .number,
    .colon {
        height: 50px;
    }
`;

// export const _like = styled.div`
//     position: absolute;
//     top: 16px;
//     right: 16px;
//     ${mediaDown('mobile')} {
//         top: 2px;
//         right: 5px;
//         width: 34px;
//         height: 34px;

//         svg {
//             width: 100%;
//             height: 100%;
//         }
//     }
// `;
export const _like = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    svg {
        width: 40px;
        height: 40px;
    }

    ${mediaDown('mobile')} {
        /* width: 24px;
        height: 24px; */
        top: 8px;
        right: 8px;

        /* svg {
            width: 24px;
            height: 24px;
        } */
    }
`;
