'use client';
import { useImageShimmer } from '@/app/_hook/useImageShimmer';
import { _categorySection, _categoryGrid, _imageWrapper, _categoryItem, _categoryImageBox, _categoryText } from '@/app/styles/components/main';
import Link from 'next/link';

const CategorySection = () => {
    const imageShimmer = useImageShimmer();

    const categories = [
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_1.jpg', title: 'ECF', href: '/info' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_2.jpg', title: '랭킹', href: '/ranking' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_3.jpg', title: '타임세일', href: '/prlist?type=sale&page=1' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_4.jpg', title: '기획전', href: '/promotion' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_5.jpg', title: '뷰티', href: '/cateprlist?category1Idx=0d45a47e-e28a-46b8-a094-0ff39de820b4' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_6.jpg', title: '푸드', href: '/cateprlist?category1Idx=d434c69b-6702-439a-bb18-f77562d98189' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_7.jpg', title: '패션', href: '/cateprlist?category1Idx=774f0397-51de-4969-8974-08b0bee82dbb' },
        { image: 'https://ecf-s3.s3.ap-northeast-2.amazonaws.com/public/images/small_banner_8.jpg', title: '라이프스타일', href: '/cateprlist?category1Idx=2a9ad380-c647-4daf-ba14-864869311b4b' },
    ];

    return (
        <_categorySection>
            <h2 className="visually-hidden">카테고리 메뉴</h2>
            <_categoryGrid>
                {categories.map((category, index) => {
                    return (
                        <Link href={category.href} key={index}>
                            <_categoryItem>
                                <_categoryImageBox>
                                    <_imageWrapper>
                                        <img src={category.image} alt={category.title} loading={index < 4 ? 'eager' : 'lazy'} decoding="async" width="262" height="131" />
                                        {/* <Image
                                            src={category.image}
                                            alt={category.title}
                                            fill
                                            sizes="(max-width: 1026px) 100vw, 262px" // 실제 렌더링되는 크기에 맞춤
                                            priority={index < 4}
                                            loading={index < 4 ? undefined : 'lazy'}
                                            quality={75}
                                            placeholder="blur"
                                            blurDataURL={imageShimmer.getBlurDataURL(262, 131)} // 실제 이미지 크기에 맞춤
                                        /> */}
                                    </_imageWrapper>
                                </_categoryImageBox>
                                <_categoryText>{category.title}</_categoryText>
                            </_categoryItem>
                        </Link>
                    );
                })}
            </_categoryGrid>
        </_categorySection>
    );
};

export default CategorySection;
